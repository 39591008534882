// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// USP section
.usp-section {
	position: relative;
	padding: 6vh 0 12vh 0;
	ul.fast-menu-home {
		display: flex;
		flex-flow: row;
		justify-content: space-around;
		gap: 1rem;
		li {
			// flex-basis: 33%;
			// Uncomment om centraal uit te lijnen a.d.h.v. gap
			a {
				display: flex;
				flex-flow: column;
				text-decoration: none;
				align-items: center;
				color: $text-dark;
				// span:not(.list-item-title):not(.list-item-suffix)

				img {
					width: 61px;

					margin-bottom: 1rem;
					i {
						color: $white;
						font-size: 24px;
					}

					transition: 0.35s ease-in;
				}
				.list-item-title {
					font-size: $h4-font-size;
					font-weight: 600;
				}
				&:hover {
					span:not(.list-item-title):not(.list-item-suffix) {
						transform: translateY(-0.35rem);
						transition: 0.35s ease-in;
					}
				}
			}
		}
		@include media-breakpoint-down(lg) {
			flex-flow: column wrap;
			// padding: 1rem;
			li {
				a {
					display: grid;
					grid-template-areas:
						"prefix title"
						"prefix suffix";
					gap: 0.25rem;
					justify-content: flex-start;


					img {
						grid-area: prefix;
						margin-right: 0.5rem;
						display: flex;
						justify-content: center;
						align-items: center;
						max-width: 50px;



					}
					.list-item-title {
						grid-area: title;
						font-size: $h5-font-size;
						padding: 0;
						margin-bottom: 0;
					}
					.list-item-suffix {
						grid-area: suffix;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
	&::before {
		content: url("/images/shadows/curve-wit.svg");
		height: 6vh;
		width: 100%;
		position: absolute;
		top: -6vh;
		left: 0;
		z-index: 1;
		@include media-breakpoint-down(lg) {
			content: "";
			background-image: url("/images/shadows/curve-wit.svg");
			background-repeat: no-repeat;
			background-position: bottom;
			transform: translateY(1px);
		}
	}
	&::after {
		content: url("/images/shadows/boog-top.svg");
		filter: drop-shadow(0px -4px 3px rgba(#000, 0.2));
		height: 6vh;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		@include media-breakpoint-down(lg) {
			content: "";
			background-image: url("/images/shadows/boog-top.svg");
			background-repeat: no-repeat;
			background-position: bottom;
		}
	}
}

// lead-section
// =========================================================================
&.home-groepsaccommodaties .lead-section,
&.home-bospaviljoen .lead-section,
&.home .lead-section {
	@include media-breakpoint-up(md) {
		padding: 3vh 0;
	}

	.container-one-column {
		.container-holder {
			max-width: 850px;
		}
	}
	&::before {
		content: "";
		height: 0;
	}
}
.lead-section {
	padding: 6vh 0;
	position: relative;
	.container-one-column {
		.container-holder {
			margin: 0 auto;
			text-align: center;

			.column {
				align-items: center;
			}
		}
	}

	// bundles
	.info-nav {
		width: 100%;
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		max-width: 850px;
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}
&[class*="accommodation"] {
	.lead-section {
		&::before {
			top: -6vh;
		}
	}
}

// content-section
// =========================================================================
&.home-groepsaccommodaties .content-section,
&.home-bospaviljoen .content-section,
&.home .content-section {
	padding: 6vh 0;
}
.content-section {
	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	&.first {
		.container-two-columns {
			padding-top: 12vh;
			.column {
				padding: 0;
				padding-right: 0 !important;
				padding-left: 0 !important;
				.page-block:not(.resource-image) {
					max-width: 75%;
					width: 75%;
					margin-left: auto;
					margin-right: auto;
				}
				img {
					margin-bottom: 3vh;
				}
			}
			.one {
				margin-top: -12vh;
			}
			.two {
				margin-left: -1rem;
			}
		}
		@include media-breakpoint-down(lg) {
			.container-two-columns {
				.column {
					.page-block:not(.resource-image) {
						max-width: 95%;
						width: 95%;
					}
				}
				.one {
					margin-top: auto;
				}

				.two {
					margin-left: auto;
				}
			}
		}
	}
	&.first,
	&.second {
		background-image: url("/images/blad-groen.svg");
		background-repeat: no-repeat;
		background-position: top left -5rem;
		background-size: 360px;
		position: relative;
		padding: 1rem;
		@include media-breakpoint-down(lg) {
			background: none;
			background-size: 150px;
			background-position: top 2.5rem left -2.5rem;
			&::before {
				content: url("/images/blad-groen.svg");
				position: absolute;
				top: 0;
				left: -2.5rem;
				width: 150px;
				aspect-ratio: 1/1;
				z-index: 1000;
				transform: translateY(50%);
			}
		}
	}
	&.second {
		position: relative;
		z-index: 990;
		padding-bottom: 12vh;
		&::after {
			content: url("/images/shadows/boog-top.svg");
			filter: drop-shadow(0px -4px 3px rgba(#000, 0.2));
			height: 6vh;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 800;
			@include media-breakpoint-down(lg) {
				content: "";
				background: url("/images/shadows/boog-top.svg");
				background-repeat: no-repeat;
				background-position: bottom;
			}
		}

		.container-two-columns {
			padding-top: 12vh;

			.column {
				padding: 0;
				padding-right: 0 !important;
				padding-left: 0 !important;

				.page-block:not(.resource-image) {
					max-width: 75%;
					width: 75%;
					margin-left: auto;
					margin-right: auto;
				}

				img {
					margin-bottom: 3vh;
				}
			}

			.two {
				margin-top: -12vh;
				@include media-breakpoint-down(lg) {
					margin-top: unset;
				}
			}

			.one {
				margin-right: -1rem;
				@include media-breakpoint-down(lg) {
					margin-right: unset;
				}
			}
			@include media-breakpoint-down(lg) {
				.column {
					.page-block:not(.resource-image) {
						max-width: 85%;
						width: 85%;
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// extra section
.extra-section {
	padding: 6vh 0;
	background: $white;
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
	background-image: url("/images/blad-groen.svg");
	background-repeat: no-repeat;
	background-position: top left -5rem;
	background-size: 360px;
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	position: relative;
	&::after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		margin: 6vh 0;
		background-image: url("/images/blad-groen.svg");
		background-repeat: no-repeat;
		background-position: top left -5rem;
		background-size: 330px;
		pointer-events: none;
		transform: scaleX(-1);
		z-index: -1;
	}
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
	background-image: url("/images/blad-groen.svg");
	background-repeat: no-repeat;
	background-position: top left -5rem;
	background-size: 360px;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

.tbs-accommodatie {
	font-size: $h5-font-size !important;
}
