&.home section.eyecacther {
	&::after {
		content: "";
		display: none;
	}
}
section.eyecatcher {
	position: relative;
	&::after {
		content: url("/images/shadows/curve-beige.svg");
		height: 6vh;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		@include media-breakpoint-down(lg) {
			content: "";
			background-image: url("/images/shadows/curve-beige.svg");
			background-repeat: no-repeat;
			background-position: bottom;
			transform: translateY(1px);
			bottom: 0;
		}
	}
}

.eyecatcher,
.eyecatcher .owl-carousel .item {
	position: relative;
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-dots,
	.owl-nav {
		display: none;
	}
	.owl-caption {
		position: relative;
		z-index: 1000;
		.owl-title {
			font-weight: 600;
			font-size: 56px;
			color: $white;
		}
		.owl-subtitle {
			color: $white;
		}
		@include media-breakpoint-down(lg) {
			margin-bottom: 24vh;
			.owl-title {
				font-size: 36px;
			}
		}
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;
	.owl-container {
		height: 100%;
		padding-bottom: 18vh;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		z-index: 900;
	}

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}
}
.eyecatcher.large .owl-carousel .owl-item {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		background-color: $black;
		opacity: 0.25;
		z-index: 1;
	}
}

.eyecatcher.medium {
	position: relative;
	&::before {
		content: url("/images/shadows/boog-bottom.svg");

		height: 6vh;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 800;
	}
	&::after {
		content: url("/images/shadows/boog-wit.svg");

		height: 6vh;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 800;
		@include media-breakpoint-down(lg) {
			content: "";
			background: url("/images/shadows/boog-wit.svg");
			background-repeat: no-repeat;
			background-position: bottom;
			transform: translateY(1px);
		}
	}
}

.eyecatcher.medium,
.eyecatcher.medium .owl-carousel .item {
	height: 90vh;
	min-height: 540px;
	max-height: 100vh;
	@include media-breakpoint-down(lg) {
		height: 50vh;
	}
}

.logo-eyecatcher {
	position: absolute;
	left: 0;
	right: 0;
	margin-right: auto;
	margin-left: auto;
	top: 12vh;
	z-index: 990;
	display: flex;
	justify-content: center;
	@include media-breakpoint-down(lg) {
		img {
			max-height: 161px;
			padding:1.2rem;
		}
	}
}

.eyecatcher.large .owl-carousel,
.eyecatcher.medium .owl-carousel,
.eyecatcher .owl-carousel {
	overflow: hidden;
	&::after {
		content: url("/images/blad-wit.svg");
		position: absolute;
		z-index: 990;
		top: 20%;
		right: -1rem;
		width: 20vw;
		aspect-ratio: 1/1;
		display: flex;
		justify-content: flex-end;
		@include media-breakpoint-down(lg) {
			content: "";
			background: url("/images/blad-wit.svg");
			top: 10%;
			width: 20vw;
			height: 20vh;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
}
.eyecatcher.medium .owl-carousel {
	&::after {
		top: 5%;
		padding: 1rem;
		z-index: 1000;
	}
}
