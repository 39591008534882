// mini-sab

&.home-groepsaccommodaties .mini-sab .container .container-holder,
&.home-bospaviljoen .mini-sab .container .container-holder,
&.home .mini-sab .container .container-holder {
	@include media-breakpoint-up(lg) {
		position: absolute;
		left: auto;
		right: auto;
		bottom: 6vh;
	}
}
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		@include media-breakpoint-down(lg) {
			margin-top: -25vh;
		}

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 1rem 1rem;
			border-radius: 50px;
			background: $white;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: primary;
			display: flex;
			align-items: center;
			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -50px;
				padding-left: 30px;
				padding-right: 30px;
			}
			@include media-breakpoint-down(lg) {
				// position: absolute;
				// left: auto;
				// right: auto;
				// bottom: -6vh;

				margin: 1.5rem;
				.tommy-zeb-update {
					max-width: 50% !important;
					margin-left: auto !important;
					margin-right: auto !important;
					transform: translateY(75%);
					color: $white !important;
				}
				select {
					display: none;
				}
			}
			// above eyecatcher
			// @include media-breakpoint-up(lg) {
			// 	position: absolute;
			// 	left: auto;
			// 	right: auto;
			// 	bottom: 3vh;
			// }
		}
	}
	select,
	input,
	button.tommy-zeb-change-person-categories {
		border: 1px solid #aebfbc !important;
		white-space: nowrap;
	}
}
