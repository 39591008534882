// btn
.btn {
	font-size: $font-size-base;
	font-weight: 400;
	border-radius: 19px;
	min-width: 224px;
	padding: 0.35rem 1.75rem !important;
	// btn-primary
	&.btn-primary {
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		background-color: $white;
		color: $primary;
		border: none;
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		border-color: $white;
		color: $black;
		&:hover {
			background-color: $white;
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: flex;
	align-items: center;
	text-decoration: none;
	transform: translateX(-15px);
	&::before {
		content: "\f0d9";
		font-weight: 900;
		font-family: $font-awesome;
		margin-right: 0.5rem;
		transition: 0.35s ease-in;
	}
	&:hover {
		&::before {
			transform: translateX(-0.25rem);
			transition: 0.35s ease-in;
		}
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

.header-btn a {
	@extend .btn, .btn-primary;
	min-width:140px;
	padding: .35rem!important;
}
