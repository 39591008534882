.card {
	border: 0;
	.card-body {
		padding: 2rem 1.5rem;
	}
	.card-caption {
		display: flex;
		flex-flow: column;
	}
	.card-title {
		font-size: $h5-font-size;
		color: $text-dark !important;
	}
	.card-subtitle {
		font-size: $h6-font-size;
		order: -1;
	}
}
