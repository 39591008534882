// footer
.footer {
	position: relative;
	.footer-logos {
		padding: 9vh 0 !important;
		background: $body-bg;
		padding: 1rem;
		ul.partner-logos {
			width: 100%;
			padding: 0 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 1rem;
			li {
				span {
					display: none;
				}
				span.list-item-prefix {
					display: block;
				}
				i {
					max-height: 65px;
					min-height: 32px;
					font-size: 50px;
					@include media-breakpoint-down(lg) {
						max-height: 40px;
					}
				}
			}
		}
	}
	.footer-outro {
		background: $primary;
		position: relative;
		padding: 3vh 0 6vh 0;
		margin-top: 6vh;
		height: 100%;
		.container {
			.container-holder {
			}
			.one {
				display: flex;
				flex-flow: column;
				justify-content: space-between;
				height: 40vh;
				.footer-text * {
					color: $white;
					a {
						font-weight: 600;
					}
				}
				@include media-breakpoint-down(lg) {
					height: auto;
					gap: 2rem;
				}
			}
		}
		&::after {
			content: url("/images/shadows/boog-groen.svg");
			filter: drop-shadow(0px -4px 3px rgba(#000, 0.2));
			height: 6vh;
			width: 100%;
			position: absolute;
			top: -6vh;
			left: 0;
			@include media-breakpoint-down(lg) {
				content: "";
				background: url("/images/shadows/boog-groen.svg");
				background-repeat: no-repeat;
				background-position: bottom;
			}
		}
		@include media-breakpoint-down(lg) {
			padding: unset !important;
			padding-top: 3vh !important;
			margin-top: 0;
		}
	}
	.footer-socket {
		background: $primary;
		.column {
			padding: 1rem 0;
			display: flex;
			flex-flow: row;
			justify-content: space-between;
			* {
				color: $white;
			}
			.footer-link .list {
				li {
					margin-right: 0.65rem;
					&:after {
						content: " | ";
					}
					&:nth-last-child(1) {
						&::after {
							content: "";
						}
					}
					a {
						text-decoration: none;
					}
					@include media-breakpoint-down(lg) {
						&:after {
							content: "";
						}
					}
				}
			}
			.vocabulary-term-list {
				z-index: 1000;
				gap: 1rem;
				span.list-item-title {
					display: none;
				}
				i {
					margin-right: 0.65rem;
					transition: 0.35s ease-in;

					font-size: 36px;

					&:hover {
						transform: translateY(-0.35rem);
						transition: 0.35s ease-in;
					}
				}
			}
		}
		@include media-breakpoint-down(lg) {
			height: 40vh;

			.container,
			.container-holder,
			.column {
				height: 100%;
			}
			.column {
				.vocabulary-term-list {
					display: flex;
					align-items: flex-end;
					flex-basis: 100%;
					justify-content: flex-end;
				}
			}
		}
	}
	&::before {
		content: "";
		background-image: url("/images/boom.svg");
		background-repeat: no-repeat;
		width: 500px;
		aspect-ratio: 1/1;
		position: absolute;
		bottom: 0;
		right: 20%;
		z-index: 1000;
		@include media-breakpoint-down(lg) {
			background-position: center bottom;
			width: 100%;
			left: 0;
			right: 0;
			background-size: 60%;
			z-index: 1000;
		}
	}
}
