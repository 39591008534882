// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: transparant;
	transition: 0.5s;

	&.sticky,
	&.mobile-sticky {
		.logo {
			visibility: visible;
			opacity: 1;
			transition: 0.5s ease-in;
		}
		box-shadow: $shadow;
		background-color: $primary;
		.fast-links a {
			color: $text-light !important;
			span:not(.list-item-title) {
				background: none;
			}
		}
		.nav-link {
			color: $text-light !important;
		}
		.header-btn a {
			@extend .btn-secondary;
		}
		ul.dropdown-menu.show {
			background: $primary !important;
			border-color: $primary !important;
		}
	}

	> [class*="container-"] {
		.container-holder {
			display: flex;

			> .column {
				@extend .align-items-center;
				justify-content: space-between;
				padding: 0.5rem 2rem;
				@include media-breakpoint-down(lg) {
					padding: 0.5rem;
					flex-flow: row wrap;
					justify-content: space-between;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		order: -1;
		.navbar-toggler {
			padding: 0;
			i {
				background: $white;
				padding: 0.35rem;
				border-radius: 28px;
				font-size: 14px;
				transition: 0.35s ease-in;
				&::before {
					color: $black !important;
				}
			}
			&::after {
				content: "Menu";
				font-size: 14px;
				color: $white;
				margin-left: 0.5rem;
			}
			&[aria-expanded="true"] {
				i {
					transform: rotate(180deg);
					transition: 0.35s ease-in;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		visibility: hidden;
		opacity: 0;
		transition: 0.5s ease-in;
		flex-basis: 100%;
		display: flex;
		justify-content: center;
		width: 75px;
		a {
			// @extend .d-block;
			display: flex;
			justify-content: center;

			img {
				// @extend .w-100;
				height: 6vh;
				transform: translateX(-25%);
				@include media-breakpoint-down(lg) {
					transform: translateX(100%);
				}
			}
		}
		@include media-breakpoint-down(lg) {
			flex-basis: unset;
			a {
				img {
					height: 4vh;
				}
			}
		}
	}
	// header-btn
	.header-btn {
	}
	// fast-links
	.fast-links {
		flex-basis: 33%;
		ul {
			.list-item-suffix {
				display: none;
			}
		}
		@include media-breakpoint-down(lg) {
			display: none !important;
		}
		@include media-breakpoint-up(xl) {
			flex-basis: 100%;
			gap: 2rem;
			a {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				gap: 0.25rem;
				margin-right: 0.65rem;
				text-decoration: none;
				color: $white;
				font-size: 14px;

				// span:not(.list-item-title):not(.list-item-suffix)
				img {
					width: 30px;
					aspect-ratio: 1/1;
					transition: 0.35s ease-in;
					i {
						font-size: 12px;
						color: $black;
					}
				}
				&:hover {
					span:not(.list-item-title):not(.list-item-suffix) {
						transform: translateY(-0.25rem);
						transition: 0.35s ease-in;
					}
				}
			}
		}
	}
	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 1;
			padding: 1rem;
			.navbar-nav {
				.nav-item {
					.nav-link {
						font-weight: 600;
					}
					&.dropdown a {
						display: flex;
						justify-content: space-between;
						align-items: center;
					}
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						border: 0 !important;
						transition: 0.35s ease-in;

						.nav-link {
							font-weight: 300;
							margin-left: 1rem;
						}
					}
				}
			}
			.domains {
				display: flex;
				width: 100%;
				justify-content: center;
				margin-top: 1rem;
				gap: 0.5rem;

				img {
					max-width: 2rem;
					height: 2rem;
				}
			}
		}

		@include media-breakpoint-up(xl) {
			flex-basis: 15%;
			max-width: fit-content;
			margin-right: 1.5rem;
			.domains {
				display: none !important;
			}
			.nav-link {
				color: $white;
				font-size: 14px;
				&:hover {
					opacity: 0.65;
				}
			}
			ul.dropdown-menu.show {
				background-color: rgba($white, 0.5);
				box-shadow: none;
				li.nav-item {
					.nav-link {
						color: $text-dark;
						font-weight: 600;
					}
				}
				&::after,
				&::before {
					display: none;
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
	}
}
