// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f00c";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0;
		display: flex;
		align-items: center;
		max-width: fit-content;
		justify-content: flex-start;
		gap:.5rem;
		&::before {
			content: "\f111";
			font-family: $font-awesome;
			color: $black;
			font-weight: 900;
			font-size: 10px;
		}
	}
}
